import React from 'react'
import Layout from '../../layouts/layout'

class MeetTheChampsOrBecomeAChamp extends React.Component {
  render () {
    return (
<Layout>
  {/* Page Header */}
        <header className="masthead meet_the_champs">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="page-heading">
                  <h1>Meet the Champs!</h1>
                  <span className="subheading">The classiest, coolest natural language processing champs around!</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main Content */}
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 mx-auto">
              <img alt="Chris, the first NLP champ!" className="img-fluid" src="" style={{borderRadius:"100%"}}/>
              <h1>Steve (^that's actually chris)</h1>
              <p>Steve is an aspiring NLP researcher and dreams of a having great NLP job some day. He needs to edit this field so it says what he wants :)</p>
            </div>
            <div className="col-lg-4 col-md-12 mx-auto">
              <img alt="Chris, the first NLP champ!" className="img-fluid" src="" style={{borderRadius:"100%"}}/>
              <h1>Chris (^that really is chris)</h1>
              <p>Chris was always interested in NLP and topics like machine learning and neural networks, but just was TOO LAZY to get into it. With Steve's encouragement and pure NLP motivation, chris built this website and starting making posts.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12 mx-auto">
              <h1>YOU!!?!?</h1>
              <p>Yeah, that's right, YOU! If you've navigated all the way to this page, you're probably interested enough in becoming a Champ! Scroll down for more.</p>
            </div>
            <div className="col-lg-4 col-md-12 mx-auto">
              <h1>Champy - The NLP Champs' Imaginary Friend?</h1>
              <p>'Nuff said.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <h1>Become a NLP Champs... Champ!</h1>
              <p>One of us... one of us... one of- nah, just kidding. But seriously, if you think YOU have what it takes to become a champ, meaning joining this project and posting a bunch, you're welcome to join the team and rise up higher than being just a guest poster. Shoot us an email!</p>
            </div>
          </div>
        </div>
</Layout>
    );
  }
}

export default MeetTheChampsOrBecomeAChamp;
